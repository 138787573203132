////
// Send a POST request to the server with the specified
// data and send the response to the passed callback
var postData = function (form, action, progress, callback) {
    "use strict";
    
    var data = new FormData(form);
    var xhr = new XMLHttpRequest();

    // add images or one image as an array of file data
    [].forEach.call(form.images.files, function (file) {
        data.append('images[]', file);
    });

    // track progress and update width of target el
    xhr.addEventListener("progress", function(e) {
        if (e.lengthComputable) {
          var percentage = Math.round((e.loaded * 100) / e.total);
          
          progress.style.width = percentage + "%";
        }
    }, false);

    // listen for response
    xhr.addEventListener("load", function () {
        var response = JSON.parse(xhr.responseText);
        callback(response);
    });

    // send request
    xhr.open("POST", fkk_vars.url + "?action=" + action);
    xhr.send(data);
};

////
// Shuffle random links on page
var shuffle = function() {
    var randomLinks = document.querySelectorAll('.js-random__item a');
    var link = randomLinks[Math.floor(Math.random() * randomLinks.length)];
    this.setAttribute("href", link);
};

////
// Get a random int between min and max values
var randomInt = function (min, max) {
    return (Math.random() * (max - min) + min).toPrecision(2);
};


document.addEventListener("DOMContentLoaded", function() {
    "use strict";

    var inViews = document.querySelectorAll('.js-in-view');
    var scrollTo = document.querySelectorAll('.js-scroll-to');
    var scatteredImages = document.querySelectorAll('.js-scattered li');

    var randomButtons = document.querySelectorAll('.js-random');
    var capsuleForms = document.querySelectorAll(".js-capsule-form");

    ////
    // random image generation
    scatteredImages.forEach(function(el, index){
        var scale = randomInt(0.7, 1.0);
        var marginTop = index === 0 ? 0 : randomInt(-10, 10);
        var translatex = randomInt(-10, 10);

        el.style.transform = "scale(" + scale + ") translatex(" + translatex + "%)";

        if (index >= 1) {
            el.style.marginTop = marginTop + "%";
        }
    });

    ////
    // handle / animate scroll to
    scrollTo.forEach(function(el) {
        el.addEventListener('click', function(e){
            e.preventDefault();

            var elId = this.getAttribute('href').replace('#', '');

            document.getElementById(elId).scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        });
    });
    
    ////
    // Listen for in view of section, add class for animation / reveal
        var observer = new IntersectionObserver(function(entries) {

        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                entry.target.classList.add('in-view');
            } else {
                entry.target.classList.remove('in-view');
            }
        });
    });

    inViews.forEach(function(el) {
        observer.observe(el);
    });

    ////
    // Init random link and add handler
    randomButtons.forEach(function (event) {
        shuffle();
        randomButton.addEventListener("click", shuffle);
    });
    
    ////
    // Add handlers for form submission and image preview generation
    capsuleForms.forEach(function (capsuleForm) {
        var capsuleFile = capsuleForm.querySelector(".js-capsule-file");
        var capsulePreviews = capsuleForm.querySelector(".js-capsule-previews");
        var capsuleMessage = capsuleForm.querySelector(".js-message");
        var capsuleSubmit = capsuleForm.querySelector(".js-submit");
        var capsuleProgress = capsuleForm.querySelector(".js-progress");

        // Process and append image previews when added to file input
        capsuleFile.addEventListener("change", function () {
            for (var i = 0; i < capsuleFile.files.length; i++) {
                var file = capsuleFile.files[i];
                var listItem = document.createElement("li");
                var imageEl = document.createElement("img");
    
                imageEl.src = URL.createObjectURL(file);
                listItem.appendChild(imageEl);
                capsulePreviews.appendChild(listItem);
            }
    
            capsulePreviews.classList.toggle("has-previews", capsuleFile.files);
        });

        // Handle form submission, errors, user feedback, redirection, etc.
        capsuleForm.addEventListener("submit", function (event) {
            event.preventDefault();

            capsuleForm.classList.add("is-loading");
            capsuleForm.classList.remove("has-message");

            // empty message content, disable submit
            capsuleMessage.innerHTML = "&nbsp";
            capsuleSubmit.setAttribute("disabled", true);

            // post form data to WP
            postData(
                capsuleForm,            // form element
                'capsule_submission',   // WP action name
                capsuleProgress,        // progress element
                function (response) {   // callback function

                    capsuleForm.classList.remove("is-loading");
                    capsuleForm.classList.add("has-message");

                    if (response.success) {
                        capsuleForm.classList.add("is-done");
                    } else {
                        capsuleSubmit.removeAttribute("disabled");
                    }

                    capsuleMessage.innerHTML = response.data;

                    setTimeout(function () {
                        capsuleForm.classList.remove("has-message");
                        capsuleMessage.innerHTML = "&nbsp";

                        if (response.success) {
                            window.location = "/capsule";
                        }
                    }, 5000);
                }
            );
        });
    });

});
